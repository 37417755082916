import { z } from 'zod'

export const checkboxSchema = (msgWhenRequired?: string) => {
	const transformedValue = z
		.literal('on')
		.optional()
		.transform(value => value === 'on')
	return msgWhenRequired
		? transformedValue.refine(_ => _, { message: msgWhenRequired })
		: transformedValue
}

export const booleanSchema = () => {
	return z
		.string()
		.refine(value => value === 'true' || value === 'false')
		.transform(value => value === 'true')
}

export const imageOptionalSchema = (maxSize: number) => {
	return z.preprocess(
		value => (value === '' ? new File([], '') : value),
		z
			.instanceof(File)
			.refine(
				file => file.name !== '' && file.size !== 0,
				'Du behöver ange en bild',
			)
			.refine(
				file => {
					return file.size <= maxSize
				},
				`Din bild är för stor. Maxstorlek är ${maxSize / 1024 / 1024}MB.`,
			),
	)
}

export const phonenumberSchema = () => {
	return z.string().refine(value => {
		const regex = new RegExp(
			'^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$',
		)
		return regex.test(value)
	}, 'Ange ett giltigt telefonnummer')
}

export const httpSchema = () => {
	return z
		.string()
		.refine(
			val =>
				val.slice(0, 8).toLocaleLowerCase() === 'https://' ||
				val.slice(0, 7).toLocaleLowerCase() === 'http://',
			{
				message: 'Url:en behöver börja med https:// eller http://',
			},
		)
}
